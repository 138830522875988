import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { FaqComponent } from './faq/faq.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyComponent } from './privacy/privacy.component';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'home',
  pathMatch: 'full',
}, {
  path: '',
  component: MainComponent,
  children: [
    {
      path: 'home',
      component: HomeComponent
    }, {
      path: 'faq',
      component: FaqComponent
    }, {
      path: 'disclaimer',
      component: DisclaimerComponent
    }, {
      path: 'privacy',
      component: PrivacyComponent
    }, {
      path: 'about',
      component: AboutComponent
    }, {
      path: '',
      loadChildren: './portfolio/portfolio.module#PortfolioModule'
    }, {
      path: '',
      loadChildren: './testimonial/testimonial.module#TestimonialModule'
    }, {
      path: 'sidebar-widgets',
      component: sidebarWidgetsComponent
    }, {
      path: '',
      loadChildren: './session/session.module#SessionModule'
    }, {
      path: '',
      loadChildren: './shop/shop.module#ShopModule'
    }, {
      path: 'about/:keyword', component: AboutComponent
    }
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
