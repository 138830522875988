import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  private languageCode: Observable<string>;

  constructor(private route: ActivatedRoute, private pageTitleService: PageTitleService) {
    /* Page title */
    this.pageTitleService.setTitle("Datenschutzerklärung");

    /* Page subTitle */
    this.pageTitleService.setSubTitle(null);
  }

  ngOnInit() {
   this.languageCode = this.route.queryParams.pipe(map<Params, string>(params => params['locale']));
   this.languageCode.subscribe(languageCode => {
    if(languageCode == "de") this.pageTitleService.setTitle("Datenschutzerklärung");
    else this.pageTitleService.setTitle("Privacy Policy");
  })
  }

}
