import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private pageTitleService: PageTitleService) {
    /* Page title */
    this.pageTitleService.setTitle("Häufig gestellte Fragen");

    /* Page subTitle */
    this.pageTitleService.setSubTitle("");
  }

  ngOnInit() {
  }

}
